import './App.css';
import axios from 'axios';
import React, { useState } from 'react';
import config from './config.json';

const baseUrl = `https://api.admin.${config.domain}`;
const loginUrl = `https://tomr-brandchain-login-admin-user.auth.eu-west-1.amazoncognito.com/login?client_id=3b4np1okj55vqod4gte2tckvgj&response_type=token&redirect_uri=https://admin.${config.domain}`;
//const loginUrl = 'https://tomr-brandchain-login-admin-user.auth.eu-west-1.amazoncognito.com/login?client_id=3b4np1okj55vqod4gte2tckvgj&response_type=token&redirect_uri=http://localhost:3000';

axios.interceptors.request.use(
  config => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [baseUrl];
    const token = localStorage.getItem('token');
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  const [jwt, setJwt] = useState(null);
  const [fetchError, setFetchError] = useState({});
  const [response, setResponse] = useState({});

  if (!jwt) {
    const url = window.location.href;
    const urlString = url.split('#')[0];
    const paramString = url.split('#')[1];
    const storedJwt = localStorage.getItem('token');
    if (paramString) {
      const urlParams = new URLSearchParams(paramString);
      const idToken = urlParams.get('id_token');
      const accessToken = urlParams.get('access_token');
      const expiresIn = urlParams.get('expires_in');
      localStorage.setItem('token', idToken);
      setJwt(idToken);
      window.location.href = urlString;
    } else if (storedJwt) {
      setJwt(storedJwt);
    }
  }

  const callApi = async (url, method, name, data) => {
    try {
      //const request = await axios.get(`${baseUrl}/user/teraffic@hotmail.com`);
      const request = await axios.request({
        url: url,
        method: method,
        data: data
      });
      console.log(request);
      const currentResponse = {...response};
      currentResponse[name] = JSON.stringify(request.data);
      setResponse(currentResponse);
      const currentError = {...fetchError};
      currentError[name] = null
      setFetchError(currentError);
    } catch (e) {
      console.log(e);
      const currentResponse = {...response};
      currentResponse[name] = null;
      setResponse(currentResponse);
      const currentError = {...fetchError};
      currentError[name] = JSON.stringify(e.response.data);
      setFetchError(currentError);
    }
  };

  const ButtonAndResponse = (props) => {
    return (
      <section>
        <button onClick={() => callApi(`${baseUrl}${props.urlPath}`, props.method, props.buttonText, props.data)}>
          {props.buttonText}
        </button>
        <p>{response[props.buttonText]}</p>
        {fetchError[props.buttonText] && (
          <p style={{ color: 'red' }}>{fetchError[props.buttonText]}</p>
        )}
      </section>
    )
  }

  return (
    <>
      <a
        className='App-link'
        href={loginUrl}
      >Login</a>
      <section>
        <p>{jwt}</p>
        <br/>
      </section>
      <ButtonAndResponse buttonText="createVerifier" urlPath="/verifier/tomshop" method="post" />
      <ButtonAndResponse buttonText="createStore1" urlPath="/verifier/tomshop/store1" method="post" />
      <ButtonAndResponse buttonText="createStore2" urlPath="/verifier/tomshop/store2" method="post" />
      <ButtonAndResponse buttonText="createStoreUser" urlPath="/store-user/tomshop/store1" method="post" data={{"username": "teraffic@hotmail.com"}} />
      <hr/>
      <ButtonAndResponse buttonText="getUser" urlPath="/user/testy" method="get" />
      <ButtonAndResponse buttonText="getVerifier" urlPath="/verifier/tomshop" method="get" />
      <ButtonAndResponse buttonText="getItem" urlPath="/item/testy/testy" method="get" />
      <hr/>
      <ButtonAndResponse buttonText="updateVerifier" urlPath="/verifier/tomshop" method="put" data={{"verifierName": "tomshop2"}} />
      <ButtonAndResponse buttonText="updateStore1" urlPath="/verifier/tomshop/store1" method="put" data={{"storeName": "store3"}} />
      <hr/>
      <ButtonAndResponse buttonText="listVerifiers" urlPath="/verifier" method="get" />
      <ButtonAndResponse buttonText="listUsers" urlPath="/user" method="get" />
      <ButtonAndResponse buttonText="listItems" urlPath="/item" method="get" />
    </>
  );
}

export default App;
